
import { defineComponent } from 'vue'

import { TechPost } from '@local/common/models/tech-post'
import { TechPostMedia } from '@local/common/models/tech-post-media'
import { MetaInfo } from 'vue-meta'

import { TechPostType } from '~/models'

const ItemPerPage = 18

export default defineComponent({
  data () {
    const techPosts : TechPostType[] = []
    const currentPage: number = Number(this.$route.query.page) || 1

    return {
      techPosts,
      currentPage
    }
  },
  fetchOnServer: false,
  async fetch (): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const techPostData = await $content('tech-post')
      .where({ techPost: { $eq: '掲載する' } }) // ne: 掲載しない  | eq: 掲載する
      .fetch<TechPost[]>().catch(handleError)

    // @ts-ignore
    const techPostDataArr: TechPostType[] = techPostData ? Array.isArray(techPostData) ? techPostData : [techPostData] : []
    const mediaIds: string[] = techPostDataArr.map(item => item.media)

    const medias = await $content('tech-post-media')
      .where({ sysId: { $in: mediaIds } })
      .fetch<TechPostMedia[]>()
      .catch(handleError)
    const mediaArr = medias ? Array.isArray(medias) ? medias : [medias] : []

    this.techPosts = techPostDataArr.map((item) => {
      // @ts-ignore
      const media = mediaArr.find(m => m.sysId === item.media)
      return {
        ...item, logoUrl: media?.logo || '', logoName: media?.name || ''
      }
    })
  },
  head (): MetaInfo {
    const title = 'TECH POSTS'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title, '他メディアに掲載されたDeNA×AIチームに関連ある記事を紹介します。')

    return {
      title,
      meta: [...extendMeta]
    }
  },
  computed: {
    page (): number {
      const currentPage = Number(this.$route.query.page) || 1
      return currentPage
    },
    totalPage () {
      return Math.ceil(this.techPosts.length / ItemPerPage)
    },
    isShowButtonMore () {
      return this.currentPage < this.totalPage
    },
    techPostDisplayed () {
      return this.techPosts.slice(0, this.currentPage * ItemPerPage)
    }
  },
  methods: {
    setRoute (q: number): void {
      this.$router.push({ query: { page: String(q) } })
    },
    nextPage () {
      this.currentPage += 1
      this.setRoute(this.currentPage)
    }
  }
})
