
import Vue from 'vue'

import { EventBannerType } from '@/models/'

export default Vue.extend({
  name: 'TopBanner',
  fetchOnServer: false,
  data () {
    const banner = {} as EventBannerType
    return {
      banner
    }
  },
  async fetch (): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const banner = await $content('top-setting')
      .fetch<EventBannerType>()
      .catch(handleError)
    this.banner = banner as EventBannerType
  },
  computed: {
    isShowBanner (): boolean {
      return !!this.banner.eventBannerImage?.trim() && !!this.banner.eventBannerUrl?.trim()
    }
  }
})
