
import Vue from 'vue'

import CKaggleModaLVideo from './ModalVideo.vue'
import CKaggleTitle from './Title.vue'
export default Vue.extend({
  name: 'CKaggleMeetup',
  components: { CKaggleModaLVideo, CKaggleTitle },
  data: () => {
    // const dataVideo = [
    //   { videoId: 'WEPwjvhaSZI', text: '動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、' },
    //   { videoId: 'Qd0WlJ1hckw', text: '動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、' },
    //   { videoId: 'fCZNDWbqnZk', text: '動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります、' }
    // ]
    const videoId = ''
    const isShowModal = false
    const dataVideo = [
      { videoId: 'WD7zBYhZ-vM', text: '動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります。' },
      { videoId: 'Ihon8Gb0nlY', text: '動画タイトルが入ります、動画タイトルが入ります、動画タイトルが入ります。' }
    ]
    return { dataVideo, videoId, isShowModal }
  },
  methods: {
    posterImage (videoId: string): string {
      return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
    },
    toggleModal (isShow: boolean, videoId: string = ''): void {
      this.isShowModal = isShow
      const clsBlock = 'block' as string
      if (this.videoId !== videoId) { this.videoId = videoId }
      if (isShow) {
        document.body.classList.add(clsBlock)
      } else {
        document.body.classList.remove(clsBlock)
      }
    }
  }
})
