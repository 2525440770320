
import Vue, { PropType } from 'vue'

import { MemberType } from '@/models/member'

import { validKaggleTitle } from '../../contentful/src/content-types/member/types'

export default Vue.extend({
  name: 'MemberList',
  props: {
    members: {
      type: Array as PropType<MemberType[]>,
      default: () => []
    },
    isShowUrl: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    kaggleCls (kaggle: string): string {
      const cssMap: Record<string, string> = {
        [validKaggleTitle[0]]: 'master',
        [validKaggleTitle[1]]: 'grand-master',
        [validKaggleTitle[2]]: 'expert',
        [validKaggleTitle[3]]: 'google-developer'
      }
      return cssMap[kaggle] || ''
    },
    kaggleTitleName (kaggle: string): string {
      if (this.isShowUrl) {
        const textMap: Record<string, string> = {
          [validKaggleTitle[0]]: 'Master',
          [validKaggleTitle[1]]: 'Grandmaster',
          [validKaggleTitle[2]]: 'Expert',
          [validKaggleTitle[3]]: 'Google Developer Expert'
        }
        return textMap[kaggle] || ''
      }
      return kaggle
    }
  }
})
