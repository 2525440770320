
import Vue from 'vue'

export default Vue.extend({
  name: 'ContactConfirmSection',

  props: {
    fields: { type: Array, default: () => [] },
    formValues: { type: Object, required: false, default: () => {} }
  }
})
