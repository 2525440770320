
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { ButtonsType, Direction, MemberType, NewsContent } from '@/models/'

import PageContentWrapper from '@/components/page-content-wrapper.vue'

import config from '@/config'

import { handleError, meta, metaProperty } from '@/plugins/helper'

export default Vue.extend({
  name: 'NewsDetail',
  components: { PageContentWrapper },
  async validate ({ params, $content }): Promise<boolean> {
    const slug: string = params.slug || ''
    const data = await $content('news/detail')
      .where({ slug })
      .fetch()
      .catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return (data && data.length > 0) as boolean
  },
  async asyncData ({ $content, params } : Context): Promise<object | void> {
    const slug: string = params.slug || ''

    const contentDetail = await $content(`news/detail/${slug}`)
      .fetch<NewsContent>()
      .catch(handleError)

    const detail: NewsContent = contentDetail as NewsContent

    // @ts-ignore
    const [prev, next] = await $content('news/detail')
      .only(['slug'])
      .sortBy('publishedAt', 'desc')
      .surround(slug)
      .fetch()
      .catch(handleError)

    const allMember = await $content('members')
      .where({ sysId: { $in: detail.members } })
      .fetch()

    const arrMemberIds: string[] = [...detail.members]
    const members: MemberType[] = []

    arrMemberIds.forEach((id, index) => {
      members[index] = allMember.find((member: MemberType) => member.sysId === id)
    })

    return { detail, members, prev, next }
  },
  fetchOnServer: false,
  data () {
    const prev = {} as Direction | null
    const next = {} as Direction | null

    const detail = {} as NewsContent
    const members = [] as MemberType[]

    return {
      detail,
      members,
      prev,
      next
    }
  },
  head (): MetaInfo {
    const { setMetaTitleAndDescription } = this.$helper
    const OGP_IMAGE_DEFAULT = `${config.url}images/common/ogp.jpg`

    const titleSEO: string = this.detail.seoTitle || this.detail.title
    const descriptionSEO: string = this.detail.seoDescription
    const ogImage: string = this.detail.ogpImage || OGP_IMAGE_DEFAULT

    const currentUrl = `${config.url}works/${this.$route.params.slug}/`

    return {
      title: titleSEO,
      titleTemplate: undefined,
      meta: [
        ...setMetaTitleAndDescription(titleSEO, descriptionSEO, false),
        metaProperty('og:image', ogImage),
        meta('twitter:image', ogImage),
        metaProperty('og:url', currentUrl),
        meta('twitter:domain', currentUrl)
      ]
    }
  },
  computed: {
    tags (): string[] {
      return this.detail.tags || []
    },
    buttonList () : ButtonsType {
      return this.detail.buttons
    },
    isShowButtonGroup () : boolean {
      return this.buttonList.length > 0
    },
    prevLink (): string | null {
      const { next, $link } = this
      return next ? $link.newsDetailPage(next.slug) : null
    },
    nextLink (): string | null {
      const { prev, $link } = this
      return prev ? $link.newsDetailPage(prev.slug) : null
    },
    message (): string {
      return `${this.detail.title} | DeNA×AI NEWS`
    }
  }
})
