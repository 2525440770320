
import Vue from 'vue'

import MenuPC from './PC.vue'
import MenuSP from './SP.vue'

import { MenuItem } from '@/models'

export default Vue.extend({
  name: 'Menu',
  components: { MenuPC, MenuSP },
  computed: {
    menuItems() {
      return [
        { id: 'about', label: 'about', link: this.$link.aboutPage() },
        { id: 'news', label: 'news', link: this.$link.newsPage() },
        { id: 'works', label: 'works', link: this.$link.worksPage() },
        { id: 'story', label: 'story', link: this.$link.storyPage() },
        { id: 'techposts', label: 'Tech Posts', link: this.$link.techPostPage() },
        { id: 'community', label: 'Community', link: this.$link.communityPage() },
        { id: 'kaggle', label: 'kaggle', link: this.$link.kagglePage() },
        { id: 'recruit', label: 'recruit', link: this.$link.recruitPage() }
      ] as MenuItem[]
    },
    menuItemsSP() {
      return [
        { id: 'about', label: 'about', link: this.$link.aboutPage() },
        { id: 'techposts', label: 'Tech Posts', link: this.$link.techPostPage() },
        { id: 'news', label: 'news', link: this.$link.newsPage() },
        { id: 'community', label: 'Community', link: this.$link.communityPage() },
        { id: 'works', label: 'works', link: this.$link.worksPage() },
        { id: 'kaggle', label: 'kaggle', link: this.$link.kagglePage() },
        { id: 'story', label: 'story', link: this.$link.storyPage() },
        { id: 'recruit', label: 'recruit', link: this.$link.recruitPage() }
      ] as MenuItem[]
    }
  },
  mounted() {
    window.addEventListener('load', () => {
      this.setActive()
    })
  },
  methods: {
    setActive(): void {
      const { name } = this.$route
      if (name === 'index') {
        return
      }

      const allItem = this.$el.querySelectorAll('a')

      Array.from(allItem).forEach((el) => {
        el.classList.remove('nuxt-link-active')
      })

      const el = this.$el.querySelector(`.menu_${name}`)
      if (!el) {
        return
      }

      el.classList.add('nuxt-link-active')
    }
  }
})
