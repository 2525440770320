
import Vue from 'vue'

import { MemberType, StoryContent } from '@/models'
import { handleError } from '@/plugins/helper'

export default Vue.extend({
  name: 'TopStory',
  data() {
    const storyList = [] as StoryContent[]
    const members = [] as MemberType[]
    return {
      storyList,
      members
    }
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context

    // @ts-ignore
    this.storyList = await $content('story/detail')
      .sortBy('publishedAt', 'desc')
      .limit(1)
      .fetch<StoryContent[]>()
      .catch(handleError)

    // @ts-ignore
    const storySysIdList: string[] = this.storyList.length > 0 ? this.storyList.reduce((a, c) => [...a, ...c.members], []) : []

    // @ts-ignore
    this.members = await $content('members')
      .where({ sysId: { $in: [...storySysIdList] } })
      .fetch()
      .catch(handleError)
  },
  computed: {
    storyDetail(): StoryContent {
      if (this.storyList.length > 0) {
        return this.storyList[0]
      }
      return {} as StoryContent
    }
  },
  methods: {
    getImage(img: string, width: number): string {
      return img ? `${img}?w=${width}` : ''
    }
  }
})
