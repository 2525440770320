
import Vue from 'vue'

import CRaggleModal from './Modal.vue'
export default Vue.extend({
  name: 'CRaggleModalRank',
  components: { CRaggleModal },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
})
