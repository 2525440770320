import { render, staticRenderFns } from "./Member.vue?vue&type=template&id=57fa81ae&scoped=true&"
import script from "./Member.vue?vue&type=script&lang=ts&"
export * from "./Member.vue?vue&type=script&lang=ts&"
import style0 from "./Member.vue?vue&type=style&index=0&id=57fa81ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fa81ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProjectMember: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/project-member.vue').default})
