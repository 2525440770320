
import Vue from 'vue'

import { StoreState } from '@/store'

export default Vue.extend({
  name: 'MenuButton',
  computed: {
    isShowMenu (): boolean {
      return (this.$store.state as StoreState).isShowMenu
    }
  },
  methods: {
    toggleMenu (): void {
      const show = !this.isShowMenu
      if (show) { this.$helper.blockScroll() } else { this.$helper.unBlockScroll() }
      this.$store.commit('SET_MENU', show)
    }
  }
})
