
import Vue, { PropType } from 'vue'

import { StoryContent } from '@/models/'

import Item from './Item.vue'

export default Vue.extend({
  name: 'List',
  components: {
    Item
  },
  props: {
    data: {
      type: Array as PropType<StoryContent[]>,
      required: true
    }
  },
  computed: {
    hasTagFilter () {
      return Array.isArray(this.data) ? this.data.length < 10 : false
    }
  }
})
