import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=7f46e20c&scoped=true&"
import script from "./Confirm.vue?vue&type=script&lang=ts&"
export * from "./Confirm.vue?vue&type=script&lang=ts&"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=7f46e20c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f46e20c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnForm: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-form.vue').default})
