
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { ButtonsType, ClientMessageContent, MemberType, StoryContent, WorkContent } from '@/models/'

import config from '@/config'

import { handleError, meta, metaProperty } from '@/plugins/helper'
import { SectionArticle, SectionBanner, SectionButtons, SectionClient, SectionMember, SectionOtherStory, SectionPaging, SectionWork } from '@/sections/story/detail/'

interface Direction {
  title: string
  slug: string
}

export default Vue.extend({
  name: 'StoryDetail',
  components: {
    SectionBanner,
    SectionMember,
    SectionArticle,
    SectionButtons,
    SectionClient,
    SectionWork,
    SectionOtherStory,
    SectionPaging
  },
  async validate({ params, $content }): Promise<boolean> {
    const slug: string = params.slug || ''
    const data = await $content('story/detail')
      .where({ slug })
      .fetch()
      .catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return (data && data.length > 0) as boolean
  },
  async asyncData({ $content, params, error }: Context): Promise<object | void> {
    const slug: string = params.slug || ''
    const handleError = (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err)
      error({ statusCode: 404, message: 'Post not found' })
    }

    const detail = (await $content(`story/detail/${slug}`).fetch<StoryContent>().catch(handleError)) as StoryContent

    const direction = await $content('story/detail').only(['slug']).sortBy('publishedAt', 'desc').surround(slug).fetch().catch(handleError)

    const members: MemberType[] = []
    if (detail.members.length > 0) {
      const memberContent = await $content('members')
        .where({ sysId: { $in: detail.members } })
        .fetch<MemberType>()

      detail.members.forEach((sysId: string) => {
        const searchMember = memberContent.find((member: MemberType) => member.sysId === sysId)
        if (searchMember) {
          members.push(searchMember)
        }
      })
    }

    const clientMessages: ClientMessageContent[] = []
    if (detail.clientMessages.length > 0) {
      const clientMessageContent = await $content('client-messages/detail')
        .where({ sysId: { $in: detail.clientMessages } })
        .fetch<ClientMessageContent>()
        .catch(handleError)

      if (clientMessageContent) {
        const data = clientMessageContent as ClientMessageContent
        const array = data.slug ? [data] : (clientMessageContent as ClientMessageContent[])

        detail.clientMessages.forEach((sysId) => {
          const cm = array.find((item) => item.sysId === sysId)
          if (cm) {
            clientMessages.push(cm)
          }
        })
      }
    }

    let works: WorkContent[] = []
    if (detail.works.length > 0) {
      const worksContent = await $content('work/detail')
        .where({ sysId: { $in: detail.works } })
        .fetch<WorkContent>()
      if (worksContent) {
        const data = worksContent as WorkContent
        const array = worksContent as WorkContent[]
        works = data.slug ? [data] : array

        works.map(async (item: any) => {
          const workMembers = await $content('members')
            .where({ sysId: { $in: item.members } })
            .fetch()

          item.members = workMembers
          return item
        })
      }
    }

    return { detail, direction, members, clientMessages, works }
  },
  data() {
    const detail = {} as StoryContent
    const direction: Direction[] = []
    const clientMessages: ClientMessageContent[] = []
    const works: WorkContent[] = []
    const otherStory = [] as StoryContent[]
    const members: MemberType[] = []

    return {
      detail,
      direction,
      clientMessages,
      works,
      otherStory,
      members
    }
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context
    const slug: string = this.$route.params.slug || ''

    const story = await $content('story/detail')
      .where({ slug: { $ne: slug } })
      .fetch<StoryContent[]>()
      .catch(handleError)

    const allStory = story as StoryContent[]
    const otherStories: StoryContent[] = []

    if (allStory.length <= 3) {
      this.otherStory = allStory
    } else {
      while (otherStories.length < 3) {
        const random = Math.floor(Math.random() * allStory.length)
        const randomStory = allStory[random]
        otherStories.push(randomStory)
        allStory.splice(random, 1)
      }

      this.otherStory = otherStories
    }
  },
  head(): MetaInfo {
    const { setMetaTitleAndDescription } = this.$helper
    const OGP_IMAGE_DEFAULT = `${config.url}images/common/ogp.jpg`

    const titleSEO: string = this.detail.seoTitle || this.detail.title
    const descriptionSEO: string = this.detail.seoDescription
    const ogImage: string = this.detail.ogpImage || OGP_IMAGE_DEFAULT

    const currentUrl = `${config.url}story/${this.$route.params.slug}/`

    return {
      title: titleSEO,
      titleTemplate: undefined,
      meta: [
        ...setMetaTitleAndDescription(titleSEO, descriptionSEO, false),
        metaProperty('og:image', ogImage),
        meta('twitter:image', ogImage),
        metaProperty('og:url', currentUrl),
        meta('twitter:domain', currentUrl)
      ]
    }
  },
  computed: {
    buttonList(): ButtonsType {
      return this.detail.buttons || []
    },
    hasButton(): boolean {
      return this.buttonList.length > 0
    },
    hasClientMessage(): boolean {
      return this.clientMessages.length > 0
    },
    hasWorks(): boolean {
      return this.works.length > 0
    },
    next(): string | null {
      const after = this.direction[0] || null
      return after ? this.$link.storyDetailPage(after.slug) : null
    },
    prev(): string | null {
      const before = this.direction[1] || null
      return before ? this.$link.storyDetailPage(before.slug) : null
    }
  },
  mounted() {
    console.log(this.detail)
  }
})
