
import Vue from 'vue'

import { MetaInfo } from 'vue-meta'

import SectionFeature from '@/sections/about/Feature.vue'
import SectionKeyVisual from '@/sections/about/KeyVisual.vue'
import SectionOverview from '@/sections/about/Overview.vue'
import SectionProblem from '@/sections/about/Problem.vue'
import SectionSlogan from '@/sections/top/slogan.vue'

export default Vue.extend({
  name: 'AboutPage',
  components: {
    SectionKeyVisual,
    SectionOverview,
    SectionFeature,
    SectionProblem,
    SectionSlogan
  },
  head (): MetaInfo {
    const title = 'ABOUT'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  }
})
