
import Vue from 'vue'

import config from '@/config'

import { NewsContent, NewsTagItem } from '@/models'
import List from '@/sections/news/List.vue'

export default Vue.extend({
  name: 'TopNews',
  components: {
    List
  },
  data () {
    const newsList = [] as NewsContent[]
    const key: string = ''
    return {
      newsList,
      key
    }
  },
  fetchOnServer: false,
  async fetch (): Promise<void> {
    const { $content } = this.$nuxt.context

    // @ts-ignore
    this.newsList = this.tagFilter && this.tagFilter !== 'all'
      ? await $content('news/detail')
        .where({ tags: { $contains: this.tagFilter } })
        .limit(3)
        .sortBy('publishedAt', 'desc')
        .fetch<NewsContent[]>()
      : await $content('news/detail')
        .limit(3)
        .sortBy('publishedAt', 'desc')
        .fetch<NewsContent[]>()
  },
  computed: {
    tags (): NewsTagItem[] {
      return config.newsTags
    },
    tagFilter (): string {
      const { tag } = this.$route.query
      return this.key ? this.key : (tag as string)
    },
    newsFiltered (): NewsContent[] {
      const { tagFilter } = this

      if (!tagFilter || tagFilter === 'all') {
        return this.newsList
      } else {
        return [...this.newsList].filter(item => item.tags && item.tags.includes(tagFilter))
      }
    }
  },
  methods: {
    refreshData (key: string): void {
      this.key = key
      this.$fetch()
    }
  }
})
