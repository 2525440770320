import { render, staticRenderFns } from "./kaggle.vue?vue&type=template&id=7c662189&"
import script from "./kaggle.vue?vue&type=script&lang=ts&"
export * from "./kaggle.vue?vue&type=script&lang=ts&"
import style0 from "./kaggle.vue?vue&type=style&index=0&id=7c662189&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeading: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/page-heading.vue').default,CBtn: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/content/c-btn.vue').default})
