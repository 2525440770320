
import Vue from 'vue'

import { TechPostType } from '@/models'

export default Vue.extend({
  name: 'TechPostList',
  props: {
    data: {
      type: Array as Vue.PropType<TechPostType[]>,
      default: () => []
    }
  }
})
