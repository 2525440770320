
import Vue from 'vue'

import Validator from '@/plugins/validator'

interface ValueValidate {
  isValid?: boolean,
  errors?: string[],
  value?: string,
}

export default Vue.extend({
  name: 'InputComponent',
  props: {
    input: { type: Object, required: false, default: () => {} },
    formValue: { type: String, required: false, default: '' }
  },

  data () {
    return {
      valueCheck: '' as string,
      errors: [] as any[]
    }
  },

  computed: {
    typeField (): string | null { return this.input.type || null },

    isTextareaField (): boolean { return this.typeField === 'textarea' },
    isRatioField (): boolean { return this.typeField === 'ratio' },
    isEmailField (): boolean { return this.typeField === 'email' },
    isInputField (): boolean { return !this.isRatioField && !this.isTextareaField },

    isRequired (): boolean { return this.input.required || false },
    isUrlField (): boolean { return this.input.isUrl || false },
    pattern (): string | null { return this.input.pattern || null },
    maxLength (): number | null { return this.input.maxLength || null },
    minLength (): number | null { return this.input.minLength || null },
    placeholder (): string | null { return this.input.placeholder || null },
    inputMatching (): string | null { return this.input.matching || null },
    ratioInputs (): string[] { return (this.isRatioField && this.input.ratio) || [] }
  },

  mounted () {
    setTimeout(() => {
      this.valueCheck = this.formValue
    }, 300)
  },

  beforeMount () {
    this.valueCheck = this.formValue
  },

  methods: {
    onInput (event: Event) {
      const { target } = event
      if (target) {
        const { isRequired, minLength, maxLength, isEmailField, inputMatching, isUrlField, isRatioField, pattern } = this
        const validator = new Validator()
        const options = { isRequired, minLength, maxLength, isEmailField, inputMatching, isUrlField, isRatioField, pattern }

        // @ts-ignore
        const valid = validator.checkValid(target, options, null) as ValueValidate

        this.$emit('updateError', { name: this.input.name, errors: valid.errors })
      }
    }
  }
})
