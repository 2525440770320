
import Vue from 'vue'

import { MetaInfo } from 'vue-meta'

import contactField from '@/config/contact'
import ContactRequest from '@/requests/ContactRequest'
import ContactConfirm from '@/sections/contact/Confirm.vue'
import ContactError from '@/sections/contact/Error.vue'
import ContactForm from '@/sections/contact/Form.vue'
import ContactSuccess from '@/sections/contact/Success.vue'

export default Vue.extend({
  name: 'ContactPage',

  components: { ContactForm, ContactConfirm, ContactSuccess, ContactError },

  data() {
    return {
      isSending: false,
      currentStep: 1,
      formValues: {
        type: '',
        family_name_kanji: '',
        first_name_kanji: '',
        family_name_kana: '',
        first_name_kana: '',
        company: '',
        website: '',
        email: '',
        email_confirm: '',
        detail: ''
      },
      recaptchaToken: ''
    }
  },

  head(): MetaInfo {
    const title = 'CONTACT'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  },

  computed: {
    fields(): object[] {
      return contactField
    }
  },

  methods: {
    changeFormStep(step: number) {
      this.currentStep = step
      this.$scrollTo('.page.contact', 300, { offset: -80 })
    },

    updateValue(obj: { name: string; value: string | undefined }) {
      const { name, value } = obj
      Object.assign(this.formValues)[name] = value || ''
    },

    submit() {
      if (this.isSending) {
        return
      }
      this.isSending = true

      const postData = Object.assign({}, this.formValues, {
        recaptchaToken: this.recaptchaToken
      })

      const { changeFormStep } = this
      ContactRequest.send(postData)
        .then((res: { status: number }) => {
          this.isSending = false
          if (res.status === 200) {
            changeFormStep(3)
          } else {
            changeFormStep(1)
          }
        })

        .catch(({ response = {} as object }) => {
          // eslint-disable-next-line no-console
          console.log(response)
          this.isSending = true
          const error = Object.assign({ data: {} as object }, response)
          const checkTypeError = Object.assign(
            { isErrorServer: false as boolean, isErrorClient: false as boolean },
            this.handleError(error.data)
          )

          if (checkTypeError.isErrorServer) {
            changeFormStep(4)
          } else if (checkTypeError.isErrorClient) {
            changeFormStep(1)
          }
        })
    },
    handleError({ status = null as number | null, message = '' as string }): object {
      let isErrorServer = true

      if (status) {
        if (status >= 500) {
          return { isErrorServer }
        }

        if (
          status === 400 &&
          (!message || message === 'Error while parsing JSON during the call')
        ) {
          return { isErrorServer }
        }

        if (status >= 400 && message) {
          // return { isErrorClient: true, errors: this.mapErrors(message || null) }
          return { isErrorServer }
        }
      }

      isErrorServer = false
      return { isErrorServer }
    },
    onRecaptchaSuccess(token: string) {
      this.recaptchaToken = token
    },
    onRecaptchaError() {
      this.recaptchaToken = ''
    },
    onRecaptchaExpired() {
      this.recaptchaToken = ''
    }

    // mapErrors (dataErrors) {
    //   let errors = {};
    //   const entries = [...dataErrors];

    //   if(entries.length > 0) {
    //     entries.forEach(entry => {
    //       errors[entry.field] = true;
    //     })
    //   }

    //   return errors;
    // }
  }
})
