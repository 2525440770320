
import Vue from 'vue'

import CKaggleModal from './Modal.vue'
export default Vue.extend({
  name: 'CKaggleModaLVideo',
  components: { CKaggleModal },
  props: {
    idYoutube: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  }
})
