
import Vue, { PropType } from 'vue'

import { CarouselOptions, MemberType, WorkType } from '@/models/'

import Carousel from '@/components/work-top-carousel/index.vue'

export default Vue.extend({
  name: 'TopWork',
  components: {
    Carousel
  },
  props: {
    data: {
      type: Array as PropType<WorkType[]>,
      default: () => [] as WorkType[]
    },
    members: {
      type: Array as PropType<MemberType[]>,
      default: () => [] as MemberType[]
    }
  },
  data() {
    const isShowCarousel: boolean = false
    return {
      isShowCarousel
    }
  },
  computed: {
    carouselConfig(): CarouselOptions {
      return {
        slidesToShow: 1,
        canSwipe: true,
        container: '.slider',
        slidesMargin: 80,
        isAutoPlay: true,
        duration: 5000
      } as CarouselOptions
    },
    carouselItems(): WorkType[] {
      this.data.forEach((work: WorkType) => {
        const { members } = work
        work.memberList = this.members.filter((item) => members.includes(item.sysId))
      })
      return this.data
    }
  },
  mounted() {
    this.isShowCarousel = true
  }
})
