import { render, staticRenderFns } from "./slogan.vue?vue&type=template&id=5d846a79&scoped=true&"
import script from "./slogan.vue?vue&type=script&lang=ts&"
export * from "./slogan.vue?vue&type=script&lang=ts&"
import style0 from "./slogan.vue?vue&type=style&index=0&id=5d846a79&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d846a79",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgRetina: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/img-retina.vue').default})
