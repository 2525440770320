
import Vue, { PropType } from 'vue'

import { CarouselOptions, WorkType } from '@/models/'

export default Vue.extend({
  name: 'Carousel',
  props: {
    options: {
      type: Object as PropType<CarouselOptions>,
      default: () => {}
    },
    cItems: {
      type: Array as PropType<WorkType[]>,
      default: () => [] as WorkType[]
    },
    slideActive: { type: Number, default: 1 },
    isShow: { type: Boolean, default: false }
  },
  computed: {
    slideTarget() {
      return this.slideActive || 1
    }
  },
  methods: {
    getTagString(tags: string[]): string {
      return tags.map((tag) => `#${tag}`).join(' ')
    }
  }
})
