
import Vue, { PropType } from 'vue'
import { MemberType } from '@/models/member'
import MemberList from '@/components/MemberList.vue'

export default Vue.extend({
  name: 'ProjectMember',
  components: { MemberList },
  props: {
    members: {
      type: Array as PropType<MemberType[]>,
      default: () => []
    }
  },
  computed: {
    isShow (): boolean {
      return this.members.length > 0
    }
  }
})
