
import Vue from 'vue'

export default Vue.extend({
  name: 'CModal',
  data: () => {
    const pageScrollY = 0 as number
    const isBlockScroll = false as boolean
    return { pageScrollY, isBlockScroll }
  },
  mounted () {
    this.blockScroll()
  },
  beforeDestroy () {
    this.enableScroll()
  },
  methods: {
    blockScroll () {
      const html: HTMLElement = document.documentElement
      this.pageScrollY = window.scrollY
      html.style.overflow = 'hidden'
      html.style.position = 'fixed'
      html.style.top = `-${this.pageScrollY}px`
      html.style.width = '100%'
      html.style.height = window.innerHeight + 'px'
    },
    enableScroll () {
      const html: HTMLElement = document.documentElement
      html.style.removeProperty('overflow')
      html.style.removeProperty('position')
      html.style.removeProperty('top')
      html.style.removeProperty('width')
      html.style.removeProperty('height')
      window.scrollTo(0, this.pageScrollY)
    }
  }
})
