
import Vue, { PropType } from 'vue'

import { MarkdownContent } from '@/models/common'

type CleanDocument = {
  [K: string]: any,
  body: any,
}

export default Vue.extend({
  name: 'PageContentWrapper',
  props: {
    document: {
      type: Object as PropType<MarkdownContent<any, any>>,
      required: true,
      default: () => ({ body: undefined }),
      validator (value: MarkdownContent<any, any>) {
        return Boolean(value && value.body)
      }
    }
  },
  computed: {
    cleanDocument (): CleanDocument {
      const rawDocument = this.document || {}

      return {
        // @ts-ignore
        ...(rawDocument._contentData || {}),
        body: rawDocument.body
      }
    }
  }
})
