
import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonDirection',
  props: {
    isLink: {
      type: Boolean,
      default: true
    },
    target: {
      type: String,
      default: '',
      validator: (value: string): boolean => {
        return ['_blank', '_self', '_parent', '_top', '', 'none'].includes(value)
      }
    },
    href: {
      type: String,
      default: '/'
    },
    direction: {
      type: String,
      default: 'left',
      validator: (value: string): boolean => {
        return ['left', 'right'].includes(value)
      }
    },
    font: {
      type: String,
      default: 'jp',
      validator: (value: string): boolean => ['en', 'jp'].includes(value)
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value: string): boolean => {
        return ['primary', 'contact'].includes(value)
      }
    }
  }
})
