
import Vue, { PropType } from 'vue'

import dayjs from 'dayjs'

import { CommunityType } from '@/models/community'

export default Vue.extend({
  name: 'CommunityItem',
  props: {
    news: {
      type: Object as PropType<Record<string, CommunityType[]>>,
      required: true,
      default: () => ({})
    },
    years: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => []
    },
    isShowYear: {
      type: Boolean,
      default: true
    },
    dateFormat: {
      type: String,
      default: 'MM.DD'
    }
  },
  methods: {
    getDateTime(dateTime: string): string {
      return dayjs(dateTime).format(this.dateFormat)
    }
  }
})
