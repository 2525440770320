
import Vue from 'vue'

import Error from '@/components/Error.vue'

export default Vue.extend({
  name: '404Page',
  components: {
    Error
  },
  layout: 'error'
})
