
import Vue from 'vue'

export default Vue.extend({
  name: 'More',
  props: {
    isLink: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#E50112'
    },
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '',
      validator: (value: string): boolean => {
        return ['_blank', '_self', '_parent', '_top', '', 'none'].includes(value)
      }
    }
  }
})
