
import { defineComponent } from 'vue'

import { SummerInternshipSetting } from '@local/common/models/summer-internship-setting'
import { TechPost } from '@local/common/models/tech-post'
import { TechPostMedia } from '@local/common/models/tech-post-media'
import { MetaInfo } from 'vue-meta'

import { FilterItem, TechPostType } from '~/models'

const ItemPerPage = 18

export default defineComponent({
  data () {
    const filterKey: string = ''
    const message: string = ''
    const tags: FilterItem[] = []
    const techPosts : TechPostType[] = []
    const currentPage: number = Number(this.$route.query.page) || 1

    return {
      filterKey,
      tags,
      message,
      techPosts,
      currentPage
    }
  },
  async fetch (): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const [techPostData, announcementMessage] = await Promise.all([
      $content('tech-post')
        .where({ summerInternship: { $ne: '掲載しない' } })
        .fetch<TechPost[]>().catch(handleError),
      $content('summer-internship-setting').fetch<SummerInternshipSetting>().catch(handleError)
    ])
    // @ts-ignore
    const techPostDataArr: TechPostType[] = techPostData ? Array.isArray(techPostData) ? techPostData : [techPostData] : []
    const mediaIds: string[] = techPostDataArr.map(item => item.media)

    const medias = await $content('tech-post-media')
      .where({ sysId: { $in: mediaIds } })
      .fetch<TechPostMedia[]>()
      .catch(handleError)
    const mediaArr = medias ? Array.isArray(medias) ? medias : [medias] : []

    this.techPosts = techPostDataArr.map((item) => {
      // @ts-ignore
      const media = mediaArr.find(m => m.sysId === item.media)
      return {
        ...item, logoUrl: media?.logo || '', logoName: media?.name || ''
      }
    })

    this.tags = [...new Set([...this.techPosts].map(item => item.summerInternship))].map(item => ({ key: item })).sort((a, b) => Number(b.key) - Number(a.key))

    this.message = (announcementMessage as SummerInternshipSetting)?.announcement?.replace(/(\\r)*\\n/g, '<br>') || ''
  },
  head (): MetaInfo {
    const title = 'SUMMER INTERNSHIP'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title, 'DeNAではAIスペシャリスト向けのサマーインターンシップを実施しています。世界トップレベルのスペシャリスト集団に仲間入りして、AIの事業活用に挑戦する就業型インターンシップです。')

    return {
      title,
      meta: [...extendMeta]
    }
  },
  computed: {
    tagFilter (): string {
      const { tag } = this.$route.query
      return this.filterKey ? this.filterKey : (tag as string)
    },
    isShowFilter () : boolean {
      return this.techPosts.length >= 0
    },
    techPostFiltered (): TechPostType[] {
      const { tagFilter } = this

      if (!tagFilter || tagFilter === 'all') {
        return this.techPosts
      } else {
        return [...this.techPosts].filter(item => item.summerInternship && item.summerInternship === this.tagFilter)
      }
    },
    totalPage () {
      return Math.ceil(this.techPostFiltered.length / ItemPerPage)
    },
    isShowButtonMore () {
      return this.currentPage < this.totalPage
    },
    techPostDisplayed () {
      return this.techPostFiltered.slice(0, this.currentPage * ItemPerPage)
    }
  },
  watch: {
    $route (to, from) {
      if (to.query.tag !== from.query.tag) {
        this.currentPage = 1
        this.setRoute(this.currentPage)
      }
    }
  },
  fetchOnServer: false,
  methods: {
    setRoute (q: number): void {
      this.$router.push({ query: { ...this.$route.query, page: String(q) } })
    },

    nextPage () {
      this.currentPage += 1
      this.setRoute(this.currentPage)
    }
  }
})
