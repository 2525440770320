
import Vue from 'vue'

import { CommunityType } from '@/models/community'

import CommunityList from '@/components/community/List.vue'

export default Vue.extend({
  name: 'TopCommunity',
  components: {
    CommunityList
  },
  data() {
    const communityData: CommunityType[] = []

    return {
      communityData
    }
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const communityData = await $content('community')
      .sortBy('eventDate', 'desc')
      .fetch<CommunityType[]>()
      .catch(handleError)

    this.communityData = (communityData as CommunityType[]) || []
  },
  computed: {
    year(): string {
      return new Date().getFullYear().toString() || '2024'
    },
    handledNews(): Record<string, CommunityType[]> {
      return {
        [this.year]: this.communityData.slice(0, 3)
      }
    },
    isShow(): boolean {
      return this.communityData.length > 0
    }
  }
})
