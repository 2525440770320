
import Vue, { PropType } from 'vue'

import { StoryContent } from '@/models/'

export default Vue.extend({
  props: {
    data: {
      type: Object as PropType<StoryContent>,
      default: null
    }
  },
  computed: {
    hasTags() {
      const { tags } = this.data
      return tags ? tags.length > 0 : false
    },
    hasMembers() {
      const { members } = this.data
      return members ? members.length > 0 : false
    },
    slug() {
      const { slug } = this.data
      return slug ? `${slug}/` : '/'
    }
  }
})
