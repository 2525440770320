
import Vue from 'vue'

import Validator from '@/plugins/validator'

import ContactInput from './Input.vue'

interface ValueValidate {
  isValid: boolean
  errors?: string[]
  value?: string
}

export default Vue.extend({
  name: 'ContactFormSection',

  components: { ContactInput },

  props: {
    fields: { type: Array, default: () => [] },
    formValues: { type: Object, required: false, default: () => {} },
    recaptchaReady: { type: Boolean, required: true, default: false }
  },

  data() {
    return {
      valuesField: {} as any,
      canCheck: false as boolean,
      formErrors: {
        type: null as any[] | null,
        family_name_kanji: null as any[] | null,
        first_name_kanji: null as any[] | null,
        family_name_kana: null as any[] | null,
        first_name_kana: null as any[] | null,
        company: null as any[] | null,
        website: null as any[] | null,
        email: null as any[] | null,
        email_confirm: null as any[] | null,
        detail: null as any[] | null
      }
    }
  },

  beforeMount() {
    this.valuesField = this.formValues
  },

  methods: {
    check() {
      if (this.canCheck && this.recaptchaReady) {
        const valueCheckError = Object.assign(
          { isError: true as boolean, errorName: '' as string },
          this.checkError()
        )
        if (!valueCheckError.isError) {
          this.$emit('check')
        } else {
          this.$scrollTo(`.page.contact input[name=${valueCheckError.errorName}]`, 300, {
            offset: -120
          })
        }
      }
    },

    checkError(): object {
      let isError = false as boolean
      let errorName = '' as string
      const refKeys = Object.keys(this.$refs).filter((item) => item)
      if (refKeys.length > 0) {
        const validator = new Validator()

        refKeys.forEach((key) => {
          if (key !== 'acceptContact') {
            const ref = this.$refs[key] as any[]
            const element =
              ref[0].$el.querySelector('input') ||
              ref[0].$el.querySelector('textarea') ||
              ref[0].$el.querySelector('select')
            if (element && element.name) {
              const dataInput = Object.assign(
                { required: false as boolean },
                this.getField(element.name)
              )
              if (dataInput) {
                const valid = Object.assign(
                  {},
                  validator.checkValid(element, {}, dataInput)
                ) as ValueValidate
                this.updateError({ name: element.name, errors: valid.errors })
                this.$emit('updateValue', { name: element.name, value: valid.value })

                if (!isError && dataInput.required && !valid.isValid) {
                  isError = true
                  errorName = element.name
                }
              }
            }
          }
        })
      }

      return { isError, errorName }
    },

    getField(name: string): object | null {
      const objFields = [...this.fields]
      let input: object | null = null

      objFields.forEach((field) => {
        const objField = Object.assign({ inputs: [] }, field)

        if (!input && [...objField.inputs].length > 0) {
          objField.inputs.forEach((inputItem: object) => {
            const objInput = Object.assign({ name: '' as string }, inputItem)
            if (objInput.name === name) {
              input = objInput
            }
          })
        }
      })

      return input
    },

    updateError(value: { name: string; errors: string[] | undefined }) {
      const { name, errors } = value
      Object.assign(this.formErrors)[name] = errors && errors.length > 0 ? errors : null
    },

    getErrorMessage(errorMessages: object, name: string): string {
      const errorName = Object.assign({
        REQUIRED_FIELD_ERROR: 'valueMissing',
        MINLENGTH_ERROR: 'MINLENGTH_ERROR',
        MAXLENGTH_ERROR: 'MAXLENGTH_ERROR',
        EMAIL_PATTERN_ERROR: 'typeMismatch',
        MATCHING_ERROR: 'patternMismatch',
        URL_ERROR: 'URL_ERROR'
      })

      const typeError: string = errorName[name]
      return Object.assign(errorMessages)[typeError]
    }
  }
})
