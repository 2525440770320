
import LogoData from '@/assets/data/logo'
import ImageLogo from '@/static/images/top/logo.png'

export default {
  name: 'Logo',
  props: {
    posY: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      baseImg: null,
      baseImageOut: null,
      baseImgResult: null,
      callBackCount: 0
    }
  },
  computed: {
    textArr () {
      return LogoData || []
    },

    logo () {
      return ImageLogo
    }
  },
  mounted () {
    window.addEventListener('load', () => {
      this.baseImg = new Image()
      this.baseImgOut = new Image()
      this.baseImgResult = new Image()

      this.baseImg.onload = () => {
        document.body.classList.add('ready')
        setTimeout(() => {
          this.setInitialText()
        }, 600)
        this.baseImgOut.src = this.logo
      }
      this.baseImg.src = this.logo
      this.baseImgResult.src = this.logo
    })
  },
  methods: {
    setInitialText () {
      this.textArr.forEach((part, i) => {
        const canvasId = 'logoPath_' + i

        const div = document.createElement('div')
        div.classList.add(`path_${i}`)
        div.style.position = 'absolute'
        div.style.left = `${part.x}px`
        div.style.top = `${part.y}px`
        div.style.width = `${part.w}px`
        div.style.height = `${part.h}px`
        div.style.backgroundSize = '100% 100%'
        div.style.backgroundPosition = 'top left'
        // div.style.backgroundImage = `url(/images/top/images/${part.name}.png)`

        const canvasChild = document.createElement('canvas')
        canvasChild.id = canvasId
        div.appendChild(canvasChild)
        this.$el.append(div)

        canvasChild.width = part.w
        canvasChild.height = part.h
        part.canvas = canvasChild

        this.showTimer(part)
      })
    },
    showTimer (param) {
      setTimeout(() => {
        this.show(param, {
          blink_num: 20, // 何回点滅するか
          blink_spd: 4, // 点滅のスピード msec
          divide_num: 180, // 縦に何分割するか
          swingWidth: 0.1, // 横に振れる幅。1 = 画像の横幅ぶん,
          callback: () => {
            this.callBackCount++
            if (this.callBackCount >= this.textArr.length) {
              setTimeout(() => {
                this.endAnimation(this.baseImg)
              }, 400)
            }
          }
        })
      }, param.delay)
    },
    show (param, options, image) {
      const ctx = param.canvas.getContext('2d')

      let bc = 0
      let bn = 0

      const bnt = param.w * options.swingWidth / options.blink_num
      const hrH = Math.ceil(param.h / options.divide_num)

      const _gritch = () => {
        bc++
        if (bc > options.blink_num) {
          if (options.callback()) { options.callback() }
          return
        } else {
          setTimeout(() => {
            _gritch()
          }, options.blink_spd)
        }

        bn = bn + bnt
        ctx.clearRect(0, 0, param.w, param.h)

        let img = image
        if (!image) {
          img = param.out ? this.baseImg : this.baseImgOut
          if (bc > options.blink_num / 4) {
            img = param.out ? this.baseImgOut : this.baseImg
          }
        }

        for (let i = 0; i < options.divide_num; i++) {
          const min = bn - param.w * options.swingWidth
          const max = param.w * options.swingWidth - bn
          const val = Math.floor(Math.random() * (max - min)) + min
          ctx.drawImage(img, param.x, param.y + i * hrH, param.w, hrH, val, i * hrH, param.w, hrH)
        }
      }

      _gritch()
    },

    endAnimation (image) {
      const canvas = document.createElement('canvas')
      canvas.id = 'lastEffect'
      this.$el.append(canvas)
      const { clientHeight, clientWidth } = this.$el
      canvas.width = clientWidth
      canvas.height = clientHeight

      this.show({
        x: 0,
        y: 0,
        w: clientWidth,
        h: clientHeight,
        canvas,
        delay: 0
      }, {
        blink_num: 40, // 何回点滅するか
        blink_spd: 4, // 点滅のスピード msec
        divide_num: 180, // 縦に何分割するか
        swingWidth: 0.015, // 横に振れる幅。1 = 画像の横幅ぶん
        callback: () => {
          setTimeout(() => {
            const canvas = this.$el.querySelector('#lastEffect')
            this.$el.removeChild(canvas)
          }, 200)
        }
      }, image)
    }
  }
}
