
import Vue from 'vue'
export default Vue.extend({
  name: 'ListControl',
  props: {
    prev: {
      type: String,
      default: '/'
    },
    next: {
      type: String,
      default: '/'
    },
    list: {
      type: String,
      default: '/'
    },
    listName: {
      type: String,
      default: 'NEWS LIST'
    }
  }
})
