
import Vue from 'vue'

import { MemberType, TopSettingType } from '@/models'

import MemberList from '@/components/MemberList.vue'

export default Vue.extend({
  name: 'TopScientist',
  components: { MemberList },
  fetchOnServer: false,
  data() {
    const members: MemberType[] = [] as MemberType[]
    return {
      members
    }
  },
  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const topSetting = await $content('top-setting').fetch<TopSettingType>().catch(handleError)
    const dataScientistMembers: string[] = (topSetting as TopSettingType).dataScientistMembers

    const members = await $content('members')
      .where({ sysId: { $in: dataScientistMembers } })
      .fetch<MemberType[]>()
      .catch(handleError)

    const allMember: MemberType[] = []
    dataScientistMembers.forEach((id, index) => {
      // @ts-ignore
      allMember[index] = members.find((member: MemberType) => member.sysId === id) as MemberType
    })

    this.members = allMember
  }
})
