
import Vue from 'vue'

import { MetaInfo } from 'vue-meta'

import Error from '@/components/Error.vue'

export default Vue.extend({
  name: 'ErrorLayout',
  components: {
    Error
  },
  head (): MetaInfo {
    const title = '404 NOT FOUND'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  }
})
