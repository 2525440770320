
import Vue from 'vue'

import { TechPostType } from '@/models'

export default Vue.extend({
  name: 'TopTechPost',
  data() {
    const techPosts: TechPostType[] = []

    return {
      techPosts
    }
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context
    const { handleError } = this.$helper

    const techPostData = await $content('tech-post')
      // .where({ summerInternship: { $ne: '掲載しない' } })
      .where({ techPost: { $eq: '掲載する' } }) // ne: 掲載しない  | eq: 掲載する
      .limit(3)
      .fetch<TechPostType[]>()
      .catch(handleError)

    // @ts-ignore
    const techPostDataArr: TechPostType[] = techPostData
      ? Array.isArray(techPostData)
        ? techPostData
        : [techPostData]
      : []
    const mediaIds: string[] = techPostDataArr?.map((item) => item?.media) || []

    const medias = await $content('tech-post-media')
      .where({ sysId: { $in: mediaIds } })
      .fetch()
      .catch(handleError)
    const mediaArr = medias ? (Array.isArray(medias) ? medias : [medias]) : []

    this.techPosts = techPostDataArr?.map((item) => {
      // @ts-ignore
      const media = mediaArr.find((m) => m.sysId === item.media)
      return {
        ...item,
        logoUrl: media?.logo || '',
        logoName: media?.name || ''
      }
    })
  }
})
