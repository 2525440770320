
import Vue from 'vue'

export default Vue.extend({
  name: 'Loading',
  data () {
    return {
      loading: true
    }
  },
  methods: {
    start () {
      this.loading = true
      document.body.classList.add('block')
    },

    finish () {
      document.body.classList.remove('block')
      if (this.$route.name !== 'index') {
        document.body.classList.add('ready')
      }
      this.loading = false
    }
  }
})
