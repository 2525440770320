
import Vue from 'vue'

import { MetaInfo } from 'vue-meta'

import { StoryContent, StoryTags } from '@/models/'

import List from '@/sections/story/List.vue'

export default Vue.extend({
  name: 'StoryPage',
  components: {
    List
  },
  async asyncData ({ $content }): Promise<Object> {
    const handleError = (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err)
    }
    const [story, tags, members] = await Promise.all([
      $content('story/detail').sortBy('publishedAt', 'desc').fetch().catch(handleError),
      $content('story/tags').fetch().catch(handleError),
      $content('members').fetch().catch(handleError)
    ])

    // Mapping members
    if (story && story.length > 0 && members && members.length > 0) {
      story.map((item: StoryContent) => {
        if (item.members && item.members.length > 0) {
          const membersMap = item.members
            .map((id) => { return members.find((m: any) => m.sysId === id) })
            .filter((member) => { return member != null })
          item.members = membersMap
        }
        return item
      })
    }

    return { story, tags }
  },

  data () {
    const story = [] as StoryContent[]
    const tags = {} as StoryTags

    return {
      story,
      tags
    }
  },

  head (): MetaInfo {
    const title = 'STORY'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  },

  computed: {
    showTagFilter () {
      if (this.story.length < 10) {
        return false
      }
      return true
    },
    tagFilter () {
      const { tag } = this.$route.query
      return tag && tag.toString()
    },
    storyFiltered (): StoryContent[] {
      const { tagFilter } = this

      if (!tagFilter || tagFilter === 'all' || !this.showTagFilter) {
        return this.story
      } else {
        return [...this.story].filter(item => item.tags && item.tags.includes(tagFilter))
      }
    }
  }
})
