
import Vue from 'vue'

import CModal from '../Modal.vue'
export default Vue.extend({
  name: 'CKaggleModal',
  components: { CModal },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
})
