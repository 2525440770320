
import Vue, { PropType } from 'vue'

import { NewsContent } from '@/models/'

import Item from './Item.vue'

export default Vue.extend({
  name: 'List',
  components: {
    Item
  },
  props: {
    data: {
      type: Array as PropType<NewsContent[]>,
      required: true
    }
  }
})
