
import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonLink',
  props: {
    target: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value: string): boolean => {
        return ['primary', 'contact'].includes(value)
      }
    },
    font: {
      type: String,
      default: 'jp',
      validator: (value: string): boolean => ['en', 'jp'].includes(value)
    }
  },
  computed: {
    isNewTab (): boolean {
      return this.target === '_blank'
    }
  }
})
