
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { NewsContent, NewsTagItem } from '@/models/'

import config from '@/config'

import List from '@/sections/news/List.vue'

export default Vue.extend({
  name: 'NewsPage',
  components: {
    List
  },
  async asyncData ({ $content }: Context): Promise<object | void> {
    const handleError = (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err)
    }
    const [news] = await Promise.all([
      $content('news/detail')
        .sortBy('publishedAt', 'desc')
        .fetch().catch(handleError)
    ])

    return { news }
  },

  data () {
    const news = [] as NewsContent[]

    return {
      news
    }
  },

  head (): MetaInfo {
    const title = 'NEWS'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  },

  computed: {
    tags (): NewsTagItem[] {
      return config.newsTags
    },
    tagFilter (): string {
      const { tag } = this.$route.query
      return tag as string
    },
    newsFiltered (): NewsContent[] {
      const { tagFilter } = this

      if (!tagFilter || tagFilter === 'all') {
        return this.news
      } else {
        return [...this.news].filter(item => item.tags && item.tags.includes(tagFilter))
      }
    },
    isShowFilter () : boolean {
      return this.news.length >= 10
    }
  }
})
