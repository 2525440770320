
import Vue, { PropType } from 'vue'

// import { Context } from '@nuxt/types'
import { WorkContent } from '@/models/'

export default Vue.extend({
  name: 'SectionDetailWork',
  props: {
    list: {
      type: Array as PropType<WorkContent[]>,
      default: () => []
    }
  }
})
