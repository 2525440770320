
import { StoreState } from '@/store'

import GlobalFooter from '@/components/Footer.vue'
import ContactButton from '@/components/Header/contact-button.vue'
import MenuButton from '@/components/Header/hamburger.vue'
import GlobalHeader from '@/components/Header/index.vue'

export default {
  components: {
    MenuButton,
    GlobalHeader,
    GlobalFooter,
    ContactButton
  },
  computed: {
    isShowMenu (): boolean {
      // @ts-ignore
      return (this.$store.state as StoreState).isShowMenu
    }
  },
  mounted () {
    window.addEventListener('load', () => {
      // @ts-ignore
      setTimeout(() => this.$nuxt.$loading.finish(), 800)
    })
  }
}
