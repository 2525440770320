
import Vue from 'vue'

import { CarouselOptions } from '@/models/'

type InterviewItemType = {
  link: string
  title: string
  content: string
  image: string
}

export default Vue.extend({
  name: 'CKaggleInterview',
  data: () => {
    const options = {
      slidesToShow: 1,
      canSwipe: true,
      container: '.kaggle-interview',
      slidesMargin: 80,
      isAutoPlay: true,
      duration: 5000
    } as CarouselOptions
    const dataCarousel: InterviewItemType[] = [
      {
        link: 'https://fullswing.dena.com/archives/3826',
        title: 'INTERVIEW',
        content: `2018年のサマーインターンに参加した横尾 修平。
            <br>ベイスターズのアナリストたちも驚くほどの成果を出したという、「横浜DeNAベイスターズのチーム強化のためのAIデータ活用」について聞きました。
            <br>インターン先、そして就職先としてDeNAを選んだ理由についても触れられています。`,
        image: '/images/kaggle/interview-img.jpeg'
      },
      {
        link: 'https://techcon2022.dena.dev/spring/session/kagglers-now/',
        title: 'INTERVIEW',
        content: `対談！Kaggle Grandmasterの仕事とは？
                <br/>全世界には約16万人のKagglerがおり、Top層であるKaggle Grandmasterは日本国内に約20人ほどいます。
                <br/>DeNAに所属する4人のKaggle Grandmasterたちが仕事や技術、Kaggleなどについて
                <br/>パネルディスカッション形式でお話しします。`,
        image: '/images/kaggle/interview-img--2.jpg'
      },
      {
        link: 'https://dena.com/jp/press/4688/',
        title: 'ARTICLE',
        content:
          'DeNA社内には「Kaggle」に参加する多くのKagglerがおり、 在籍するKaggle GrandmasterとKaggle Masterの数は国内最多です。 その知識やスキルは、様々なビジネス領域で活かされており、DeNA以外の企業との取り組みにおいても活用されています。',
        image: '/images/kaggle/interview-img--3.png'
      }
    ]
    return { options, dataCarousel }
  }
})
