
import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonForm',
  props: {
    disable: { type: Boolean, default: false },
    direction: {
      type: String,
      default: 'left',
      validator: (value: string): boolean => {
        return ['left', 'right'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'default',
      validator: (value: string): boolean => {
        return ['default', 'blue'].includes(value)
      }
    }
  },

  methods: {
    action ():void {
      if (this.disable) {
        return
      }

      this.$emit('action')
    }
  }
})
