
import Vue, { PropType } from 'vue'

import { StoreState } from '@/store'

import config from '@/config'

import { MenuItem } from '@/models'

export default Vue.extend({
  name: 'MenuSP',
  props: {
    mItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    }
  },
  computed: {
    isShowMenu (): boolean {
      return (this.$store.state as StoreState).isShowMenu
    },
    twitterLink (): string {
      return config.external_link.twitter || ''
    },
    facebookLink (): string {
      return config.external_link.facebook || ''
    },
    blogLink (): string {
      return config.external_link.blog || ''
    },
    privacyPolicyLink (): string {
      return config.external_link.privacy_policy
    }
  },
  methods: {
    closeMenu (): void {
      this.$helper.unBlockScroll()
      this.$store.commit('SET_MENU', false)
    }
  }
})
