
import Vue from 'vue'

export default Vue.extend({
  name: 'Feature',
  mounted () {
    this.onScroll()
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  },
  methods: {
    onScroll () {
      if (window.innerWidth <= 960) {
        return
      }

      const el = this.$refs.ship as HTMLElement
      const parent = el.parentElement as HTMLElement
      const scrollTop = parent.getBoundingClientRect().top
      const midPoint = (window.innerHeight / 2) - (el.clientHeight / 2)
      const scrollBottom = parent.getBoundingClientRect().top + parent.clientHeight - el.clientHeight - midPoint

      if (window.innerWidth > 1440) {
        const x = Math.round((window.innerWidth - 1440) / 2)
        const width = parent.clientWidth + x
        if (width <= 930) {
          el.style.width = width + 'px'
          el.style.transform = `translateX(-${x}px)`
        } else {
          el.style.width = '930px'
          el.style.transform = `translateX(-${930 - parent.clientWidth}px)`
        }
      } else {
        el.style.width = parent.clientWidth + 'px'
      }

      if (scrollTop > midPoint) {
        el.style.position = 'absolute'
        el.style.top = '0'
        el.style.bottom = 'auto'
      } else if (scrollBottom < 0) {
        el.style.position = 'absolute'
        el.style.top = 'auto'
        el.style.bottom = '0'
      } else {
        el.style.position = 'fixed'
        el.style.top = midPoint.toFixed(0) + 'px'
        el.style.bottom = 'auto'
      }
    }
  }
})
