
import Vue from 'vue'

import dayjs from 'dayjs'

import { TechPostType } from '@/models'

export default Vue.extend({
  name: 'TechPostItem',
  props: {
    data: {
      type: Object as Vue.PropType<TechPostType>,
      default: () => ({} as TechPostType)
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    date () {
      return dayjs(this.data.publishDate).format('YYYY.M.D')
    }
  },
  methods: {
    onHover () {
      this.isHover = true
    },
    onLeave () {
      this.isHover = false
    }
  }
})
