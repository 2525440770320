
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'Debug',
  props: {
    value: {
      required: false,
      default: undefined,
      validator () {
        return true
      }
    },
    title: {
      type: String as PropType<string>,
      required: false,
      default: ''
    }
  },
  mounted () {
    if (!window) {
      return
    }

    // @ts-ignore
    const holder = window.__Site_Debug || (window.__Site_Debug = [])
    holder.push({
      title: this.title,
      value: this.value
    })
  }
})
