
import Vue, { PropType } from 'vue'

import { ClientMessageContent } from '@/models/'

import PageContentWrapper from '@/components/page-content-wrapper.vue'

export default Vue.extend({
  name: 'StoryDetailClientMsg',
  components: { PageContentWrapper },
  props: {
    list: {
      type: Array as PropType<ClientMessageContent[]>,
      default: () => []
    }
  }
})
