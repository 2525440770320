
import Vue from 'vue'

export default Vue.extend({
  name: 'NewsItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
})
