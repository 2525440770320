
import Vue from 'vue'

import CKaggleTitle from './Title.vue'

export default Vue.extend({
  name: 'KaggleRanksSection',

  components: { CKaggleTitle },

  data () {
    return {
      ticking: false as boolean,
      isEnablePrev: false as boolean,
      isEnableNext: true as boolean
    }
  },

  mounted () {
    const elWrapperRank = document.querySelector(
      '.kaggle-ranks .kaggle-ranks__img'
    )
    const elImgRank = document.querySelector(
      '.kaggle-ranks .kaggle-ranks__img img'
    )

    if (elWrapperRank && elImgRank) {
      elWrapperRank.addEventListener('scroll', () =>
        this.checkImgScroll(elWrapperRank, elImgRank)
      )
    }
  },

  methods: {
    checkImgScroll (elWrap: Element, elImg: Element) {
      const wrapClientRect = elWrap.getBoundingClientRect()
      const { ticking } = this

      if (!ticking && wrapClientRect.width < 901) {
        window.requestAnimationFrame(() => {
          const imgClietnRect = elImg.getBoundingClientRect()
          this.isEnablePrev = wrapClientRect.x > imgClietnRect.x
          this.isEnableNext = wrapClientRect.right < imgClietnRect.right - 1
          this.ticking = false
        })
      }
      this.ticking = true
    }
  }
})
