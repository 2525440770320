
import Vue from 'vue'

import { MemberType } from '@/models/member'

export default Vue.extend({
  name: 'WorkItem',
  props: {
    type: { type: String, default: '' },
    data: { type: Object, default: () => ({}) },
    members: { type: Array as Vue.PropType<MemberType[]>, default: () => [] },
    isOther: { type: Boolean, default: false },
    isWorkTop: { type: Boolean, default: false }
  },
  computed: {
    imageSize(): number {
      return this.isWorkTop ? 3640 : 1760
    }
  }
})
