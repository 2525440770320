
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { ButtonsType, MemberType, StoryContent, WorkContent } from '@/models/'

import PageContentWrapper from '@/components/page-content-wrapper.vue'

import config from '@/config'

import { handleError, meta, metaProperty } from '@/plugins/helper'
import Item from '@/sections/works/Item.vue'

export default Vue.extend({
  name: 'WorkDetail',

  components: { PageContentWrapper, Item },

  async validate({ params, $content }): Promise<boolean> {
    const slug: string = params.slug || ''
    const data = await $content('work/detail')
      .where({ slug })
      .fetch()
      .catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return (data && data.length > 0) as boolean
  },

  async asyncData({ $content, params, $link }: Context): Promise<object | void> {
    const slug: string = params.slug || ''

    const work = await $content(`work/detail/${slug}`).fetch<WorkContent>().catch(handleError)
    const detail = work as WorkContent

    // @ts-ignore
    const [prevWork, nextWork] = await $content('work/detail')
      .only(['slug'])
      .sortBy('publishedAt', 'desc')
      .surround(slug)
      .fetch()
      .catch(handleError)

    const prev: string | null = nextWork ? $link.workDetailPage(nextWork.slug) : null
    const next: string | null = prevWork ? $link.workDetailPage(prevWork.slug) : null

    const allStories = await $content('story/detail')
      .where({ sysId: { $in: [...detail.story] } })
      .sortBy('publishedAt', 'desc')
      .fetch<StoryContent>()
      .catch(handleError)

    const stories: StoryContent[] = []
    detail.story.forEach((id: string, index: number) => {
      // @ts-ignore
      stories[index] = allStories.find((member: MemberType) => member.sysId === id)
    })

    let arrMemberIds: any = [...detail.members]
    let memberStory: string[] = []

    if (stories) {
      // @ts-ignore
      stories.forEach((itemStory) => {
        memberStory = [...memberStory, ...itemStory.members]
      })
    }

    arrMemberIds = [...arrMemberIds, ...memberStory].reduce(
      (a, c) => (a.includes(c) ? [...a] : [...a, c]),
      []
    )

    const allMember = await $content('members')
      .where({ slug: { $in: arrMemberIds } })
      .fetch()
      .catch(handleError)

    const members: MemberType[] = []
    arrMemberIds.forEach((id: string, index: number) => {
      // @ts-ignore
      members[index] = allMember.find((member: MemberType) => member.sysId === id)
    })

    return { detail, prev, next, members, stories }
  },

  data() {
    const detail = {} as WorkContent
    const otherWorks = [] as WorkContent[]
    const members = [] as MemberType[]
    const stories = [] as StoryContent[]
    const prev: string | null = null
    const next: string | null = null

    return {
      detail,
      otherWorks,
      members,
      stories,
      prev,
      next
    }
  },

  fetchOnServer: false,

  async fetch(): Promise<void> {
    const { $content } = this.$nuxt.context
    const slug: string = this.$route.params.slug || ''

    // @ts-ignore
    const works = await $content('work/detail')
      .without(['members'])
      .where({ slug: { $ne: slug } })
      .fetch<WorkContent[]>()
      .catch(handleError)

    const allWorks = works as WorkContent[]
    const otherWorks: WorkContent[] = []

    if (allWorks.length <= 3) {
      this.otherWorks = allWorks
    } else {
      while (otherWorks.length < 3) {
        const random = Math.floor(Math.random() * allWorks.length)
        const randomWork: WorkContent = allWorks[random]
        otherWorks.push(randomWork)
        allWorks.splice(random, 1)
      }
    }

    this.otherWorks = otherWorks
  },

  head(): MetaInfo {
    const { setMetaTitleAndDescription } = this.$helper
    const OGP_IMAGE_DEFAULT = `${config.url}images/common/ogp.jpg`

    const titleSEO: string = this.detail.seoTitle || this.detail.title
    const descriptionSEO: string = this.detail.seoDescription
    const ogImage: string = this.detail.ogpImage || OGP_IMAGE_DEFAULT

    const currentUrl = `${config.url}works/${this.$route.params.slug}/`

    return {
      title: titleSEO,
      titleTemplate: undefined,
      meta: [
        ...setMetaTitleAndDescription(titleSEO, descriptionSEO, false),
        metaProperty('og:image', ogImage),
        meta('twitter:image', ogImage),
        metaProperty('og:url', currentUrl),
        meta('twitter:domain', currentUrl)
      ]
    }
  },

  computed: {
    tags(): string[] {
      return this.detail.tags || []
    },
    buttonList(): ButtonsType {
      return this.detail.buttons
    },
    isShowStories(): boolean {
      return this.stories && this.stories.length >= 1
    },
    message(): string {
      return `${this.detail.title} | DeNA×AI WORKS`
    }
  },

  methods: {
    getImgMembers(idMember: string[]): MemberType[] {
      const members = this.members

      if (members && members.length > 0 && idMember && idMember.length > 0) {
        return [...members].filter((item) => item.slug && idMember.includes(item.slug))
      }

      return []
    }
  }
})
