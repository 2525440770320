
import Vue, { PropType } from 'vue'

import { FilterItem } from '@/models'

export default Vue.extend({
  name: 'FilterData',
  props: {
    tags: {
      type: Array as PropType<FilterItem[]>,
      default: () => []
    }
  },
  data () {
    return {
      isShowMore: false,
      isExpand: false,
      filterDataHeight: '',
      height: 40
    }
  },
  computed: {
    hashTags (): FilterItem[] {
      const { tag } = this.$route.query
      return this.tags
        .filter(item => item.key !== tag)
    },
    selectedTag (): string | null {
      const { tag } = this.$route.query
      const textTag = Array.isArray(tag) ? tag[0] : tag
      if (textTag && textTag.toString().toLowerCase() !== 'all') {
        return textTag as string
      } else { return 'all' }
    },
    isShowSelectedTag (): boolean {
      return !!(this.selectedTag && this.selectedTag.toLowerCase() !== 'all')
    }
  },
  mounted () {
    this.checkTagToShowButton()

    window.addEventListener('resize', () => {
      this.checkTagToShowButton()
      this.close()
    })
  },
  methods: {
    selectMe (tag: string): void {
      this.setRoute(tag)
      this.close()
      this.$emit('onSelect', tag)
    },
    setRoute (q: string): void {
      this.$router.push({ query: { ...this.$route.query, tag: q } })
    },
    checkTagToShowButton () {
      const filterElement: HTMLElement = document.getElementById('filter') as HTMLElement
      const children: Array<Element> = Array.from(this.$el.querySelectorAll('.item'))

      const containerWidth: number = filterElement.clientWidth || 0
      const childrenTotalWidth: number = children.map(child => child && child.clientWidth).reduce((a, c) => a + c)
      if (childrenTotalWidth > containerWidth - 80) { this.isShowMore = true } else { this.isShowMore = false }
    },
    expand (): void {
      this.isExpand = !this.isExpand
      if (this.isExpand) {
        const filterElement: HTMLElement = document.getElementById('filter') as HTMLElement
        const list: HTMLElement | null = filterElement.querySelector('#listTag') as HTMLElement
        const listHeight: number = list ? list.clientHeight : 0
        this.height = listHeight
      } else {
        this.close()
      }
    },
    close (): void {
      this.height = 40
      this.isExpand = false
    }
  }
})
