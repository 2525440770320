import { render, staticRenderFns } from "./news.vue?vue&type=template&id=7d895084&scoped=true&"
import script from "./news.vue?vue&type=script&lang=ts&"
export * from "./news.vue?vue&type=script&lang=ts&"
import style0 from "./news.vue?vue&type=style&index=0&id=7d895084&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d895084",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DataFilter: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/data-filter.vue').default,BtnDirection: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-direction.vue').default})
