
import Vue from 'vue'

export default Vue.extend({
  name: 'StoryDetailPaging',
  props: {
    prev: { type: String, default: '' },
    next: { type: String, default: '' },
    title: { type: String, default: '' }
  },
  computed: {
    message (): string {
      return `${this.title} | DeNA×AI STORY`
    }
  }
})
