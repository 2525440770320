var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu_group"},[_c('div',{staticClass:"menu_group_item"},[_c('a',{attrs:{"href":_vm.twitterLink,"target":"_blank"}},[_vm._v("X（旧Twitter）")])]),_vm._v(" "),_c('div',{staticClass:"menu_group_item"},[_c('a',{attrs:{"href":_vm.facebookLink,"target":"_blank"}},[_vm._v("Facebook")])])]),_vm._v(" "),_c('div',{staticClass:"menu_group"},[_c('div',{staticClass:"menu_group_item"},[_c('a',{attrs:{"target":"_blank","href":_vm.blogLink}},[_vm._v("\n            Blog\n          ")])]),_vm._v(" "),_c('div',{staticClass:"menu_group_item"},[_c('a',{attrs:{"href":_vm.privacyPolicyLink,"target":"_blank"}},[_vm._v("Privacy Policy")])])])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('btn-link',{attrs:{"type":"contact","font":"en","href":_vm.$link.contactPage()}},[_vm._v("\n        contact\n      ")]),_vm._v(" "),_c('btn-link',{staticClass:"bt2",attrs:{"href":"/files/DeNA×AI_Sales_Proposal.pdf","target":"_blank"}},[_vm._v("\n        資料ダウンロード\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"copyright"},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: 'body',
        duration: 800,
        onStart: _vm.onStart,
        onDone: _vm.onDone,
        easing: 'linear'
      }),expression:"{\n        el: 'body',\n        duration: 800,\n        onStart: onStart,\n        onDone: onDone,\n        easing: 'linear'\n      }"}],staticClass:"scroll_up"},[_c('span')]),_vm._v("\n    ©DeNA Co., Ltd. All rights reserved.\n  ")]),_vm._v(" "),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"brand"},[_c('img',{attrs:{"src":"/images/logo_bottom.png","alt":"DeNA AI"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"images"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":"/images/top/footer_left.gif","alt":"footer"}})]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":"/images/top/footer_right.gif","alt":"footer"}})])])
}]

export { render, staticRenderFns }