
import Vue from 'vue'

export default Vue.extend({
  name: 'ContentButton',
  props: {
    target: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  mounted () {
    window.setTimeout(() => this.setButtonWidth(), 200)
    const ref = this.$refs.span as HTMLElement
    if (ref) {
      ref.innerHTML = this.text || ''
    }
  },
  methods: {
    setButtonWidth () {
      const el: HTMLElement = this.$refs.text as HTMLElement
      const btnWidth: number = el?.clientWidth || 0
      if (btnWidth > 300 && btnWidth < 500) { this.$el.classList.add('large') } else if (btnWidth >= 500) { this.$el.classList.add('large-break') }
    }
  }
})
