import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=060442f2&scoped=true&"
var script = {}
import style0 from "./Error.vue?vue&type=style&index=0&id=060442f2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060442f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgRetina: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/img-retina.vue').default,BtnDirection: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-direction.vue').default})
