
import Vue from 'vue'

import Menu from './Menu/index.vue'

export default Vue.extend({
  name: 'GlobalHeader',
  components: {
    Menu
  }
})
