
import Vue, { PropType } from 'vue'

import { IContentDocument } from '@nuxt/content/types/content'

import PageContentWrapper from '@/components/page-content-wrapper.vue'

export default Vue.extend({
  name: 'StoryDetailArticle',
  components: { PageContentWrapper },
  props: {
    content: {
      type: Object as PropType<IContentDocument>,
      default: () => {}
    }
  }
})
