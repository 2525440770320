
import Vue, { PropType } from 'vue'

import { StoryContent } from '@/models/'

export default Vue.extend({
  props: {
    list: {
      type: Array as PropType<StoryContent[]>,
      default: () => []
    }
  }
})
