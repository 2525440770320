
import Vue from 'vue'

export default Vue.extend({
  name: 'Share',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      twitterLink: '',
      facebookLink: ''
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.makeLinkShare()
    })
  },
  methods: {
    makeLinkShare () {
      const message: string = `${this.message}\n${window.location.href}\n #DeNA_AI #DeNA #AI`
      this.facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
      this.twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`
    }
  }
})
