
import Vue, { PropType } from 'vue'

import NewList from '@/sections/news/List.vue'

import { NewsContent } from '@/models/'

import CKaggleTitle from './Title.vue'

export default Vue.extend({
  name: 'CKaggleNews',
  components: { NewList, CKaggleTitle },
  props: {
    news: {
      type: Array as PropType<NewsContent[]>,
      required: false,
      default: () => []
    }
  }
})
