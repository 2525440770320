
import Vue, { PropType } from 'vue'

import { MemberType } from '@/models/member'

export default Vue.extend({
  name: 'StoryDetailMember',
  props: {
    list: {
      type: Array as PropType<MemberType[]>,
      default: () => []
    }
  },
  computed: {
    isEmpty () {
      if (this.list.length > 0) {
        return false
      }
      return true
    }
  }
})
