
import Vue from 'vue'

import { Context } from '@nuxt/types'

import { handleError, meta, metaProperty } from '@/plugins/helper'
import config from '~/config'

import { CommunityType } from '@/models/community'

import PageHeading from '@/components/common/page-heading.vue'
import CommunityList from '@/components/community/List.vue'

export default Vue.extend({
  name: 'TechnologySharingPage',
  components: {
    PageHeading,
    CommunityList
  },
  async asyncData({ $content }: Context): Promise<object | void> {
    const news = await $content('community').sortBy('eventDate', 'desc').fetch().catch(handleError)

    return { news: news || [] }
  },
  data() {
    const news: CommunityType[] = []
    return {
      news,
      currentPage: 1,
      itemsPerPage: 5
    }
  },
  head() {
    const title = 'DeNA+GO AI Community'
    const description =
      'DeNA×AIでは、GO株式会社と共同でAI技術に関する情報を共有するための勉強会を開催しています。使われた資料は社外秘を除き積極的に公開しています。'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title, description)
    const OGP_IMAGE = `${config.url}community/ogp.png`
    const currentUrl = config.url + 'community/'

    return {
      title,
      meta: [
        ...extendMeta,
        metaProperty('og:image', OGP_IMAGE),
        meta('twitter:image', OGP_IMAGE),
        metaProperty('og:url', currentUrl),
        meta('twitter:domain', currentUrl)
      ]
    }
  },
  computed: {
    newsGroupedByYear(): Record<string, CommunityType[]> {
      return (
        this.news.reduce((acc: Record<string, CommunityType[]>, currentValue: CommunityType) => {
          const year = currentValue.eventDateDetail.year
          if (!acc[year]) {
            acc[year] = []
          }
          acc[year].push(currentValue)
          return acc
        }, {}) || {}
      )
    },
    newsGroupKeys(): string[] {
      return Object.keys(this.newsGroupedByYear).sort((a, b) => parseInt(b) - parseInt(a))
    },
    paginatedYears(): string[] {
      return this.newsGroupKeys.slice(0, this.itemsPerPage * this.currentPage)
    },
    totalPage(): number {
      return Math.ceil(this.newsGroupKeys.length / this.itemsPerPage)
    },
    isShowButtonMore(): boolean {
      return this.currentPage < this.totalPage
    }
  },
  methods: {
    handlePagination() {
      this.currentPage++
    }
  }
})
