
import Vue from 'vue'

import config from '@/config/'

export default Vue.extend({
  name: 'GlobalFooter',
  computed: {
    twitterLink (): string {
      return config.external_link.twitter || ''
    },
    facebookLink (): string {
      return config.external_link.facebook || ''
    },
    blogLink (): string {
      return config.external_link.blog || ''
    },
    privacyPolicyLink (): string {
      return config.external_link.privacy_policy
    }
  },
  methods: {
    onStart () : void {
      const kv = document.querySelector('.kv')
      kv?.classList.add('hidden')
    },
    onDone () : void {
      const kv = document.querySelector('.kv')
      setTimeout(() => {
        kv?.classList.remove('hidden')
      }, 100)
    }
  }
})
