
import Vue from 'vue'

export default Vue.extend({
  name: 'ImgRetina',
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    }
  },
  computed: {
    imgWidth () : string {
      return `${this.width / 2}px`
    }
  }
})
