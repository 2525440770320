
import Vue, { PropType } from 'vue'

import { MenuItem } from '@/models'

export default Vue.extend({
  name: 'MenuPC',
  props: {
    mItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    }
  }
})
