
import Vue, { PropType } from 'vue'

import { ButtonsType } from '@/models/'

export default Vue.extend({
  name: 'StoryDetailButtons',
  props: {
    list: {
      type: Array as PropType<ButtonsType>,
      default: () => []
    }
  }
})
