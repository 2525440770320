
import Vue from 'vue'

export default Vue.extend({
  name: 'StoryDetailBanner',
  props: {
    image: { type: String, default: '' },
    title: { type: String, default: '' },
    time: { type: String, default: '' },
    tags: { type: Array, default: () => [] },
    client: { type: String, default: '' }
  }
})
