
import Vue, { PropType } from 'vue'

import { MemberType } from '@/models/'

import MemberList from '@/components/MemberList.vue'

import CKaggleModal from './Modal.vue'
import CKaggleTitle from './Title.vue'

export default Vue.extend({
  name: 'CKaggler',
  components: { MemberList, CKaggleModal, CKaggleTitle },
  props: {
    members: {
      type: Array as PropType<MemberType[]>,
      default: () => []
    }
  },
  data: () => {
    const isShowModal = false as boolean
    const innerWidth = 0
    return { isShowModal, innerWidth }
  },
  mounted () {
    this.innerWidth = window.innerWidth
    window.addEventListener(
      'resize',
      this.$helper.debounce(() => {
        this.innerWidth = window.innerWidth
      }, 50)
    )
  },
  methods: {
    toggleModal (isShow: boolean): void {
      this.isShowModal = isShow
    },
    getImgMembers (): MemberType[] {
      const members = this.members

      if (members && members.length > 0) {
        return members.length > 100 ? [...members].slice(0, 100) : members
      }
      return []
    }
  }
})
