
import Vue from 'vue'

import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  name: 'Recruit',
  head (): MetaInfo {
    const title = 'RECRUIT'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  }
})
