
import Vue from 'vue'

import { NewsContent } from '@/models'

export default Vue.extend({
  name: 'TopNewsSticky',
  data () {
    const newsList = [] as NewsContent[]
    const debounceResize: any = null

    return {
      isClose: false,
      isOverflow: false,
      debounceResize,
      newsList
    }
  },
  async fetch (): Promise<void> {
    const { $content } = this.$nuxt.context
    // @ts-ignore
    this.newsList = await $content('news/detail')
      .only(['publishedAtStr', 'title', 'slug'])
      .sortBy('publishedAt', 'desc')
      .limit(1)
      .fetch<NewsContent[]>()
  },
  mounted () {
    this.checkOverflow()
    this.debounceResize = this.$helper.debounce(this.checkOverflow, 500)
    window.addEventListener('resize', this.debounceResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debounceResize)
  },
  methods: {
    close (): void {
      this.isClose = true
    },
    checkOverflow (): void {
      const container = this.$el.querySelector<HTMLElement>('.message')
      const element = this.$el.querySelector<HTMLElement>('.message .inline')

      if (element && container) {
        this.isOverflow = element.scrollWidth > container.clientWidth
      }
    }
  }
})
