
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { MemberType, WorkContent, WorkTags } from '@/models/'

import Item from '@/sections/works/Item.vue'

export default Vue.extend({
  name: 'WorksPage',

  components: { Item },

  async asyncData ({ $content }: Context): Promise<object | void> {
    const handleError = (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err, $content)
    }
    const [works, workTag] = await Promise.all([
      $content('work/detail')
        .sortBy('publishedAt', 'desc')
        .fetch().catch(handleError),
      $content('work/tags').fetch().catch(handleError)
    ])

    let arrMember: unknown = []
    if (works) {
      // @ts-ignore
      works.forEach((work) => {
        arrMember = [...work.members]
      })
    }

    const members = await $content('members')
      .where({ slug: { $in: arrMember } })
      .only(['slug', 'nameJp', 'image'])
      .fetch()
      .catch(handleError)

    return { works, tags: workTag, members }
  },

  data () {
    const works = [] as WorkContent[]
    const tags = {} as WorkTags
    const members = [] as MemberType[]
    const isShowTags = false as boolean
    const { tag } = this.$route.query
    const tagFilter = Array.isArray(tag) ? tag[0] : tag

    return { works, tags, members, isShowTags, tagFilter }
  },

  head (): MetaInfo {
    const title = 'WORKS'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  },

  computed: {
    isShowFilter () : boolean {
      return this.works && this.works.length >= 9
    },

    worksData (): WorkContent[] {
      return this.works
    },

    worksFiltered (): WorkContent[] {
      const { tagFilter } = this

      if (!tagFilter || tagFilter === 'all') {
        return this.worksData
      } else {
        return [...this.worksData].filter(item => item.tags && item.tags.includes(tagFilter))
      }
    }
  },

  methods: {
    getImgMembers (idMember: string[]): MemberType[] {
      const { members } = this

      if (members && members.length > 0 && idMember && idMember.length > 0) {
        return [...members].filter(item => item.slug && idMember.includes(item.slug))
      }

      return []
    },
    handleFilter (key: string | null) {
      this.tagFilter = key
    }
  }
})
