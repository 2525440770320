
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { handleError } from '@/plugins/helper'

import { MemberType, NewsContent } from '@/models/'

import CKaggleAbout from '@/components/Kaggle/About.vue'
import CKaggleInterview from '@/components/Kaggle/Interview.vue'
import CKaggler from '@/components/Kaggle/Kaggler.vue'
import CKaggleKV from '@/components/Kaggle/Kv.vue'
import CKaggleMeetup from '@/components/Kaggle/Meetup.vue'
import CKaggleModalRank from '@/components/Kaggle/ModalRank.vue'
import CKaggleNews from '@/components/Kaggle/News.vue'
import Ranks from '@/components/Kaggle/Ranks.vue'
import CKaggleSupport from '@/components/Kaggle/Support.vue'

type Member = MemberType & {
  createdAt: string
  updateAt: string
}
export default Vue.extend({
  name: 'KagglePage',

  components: {
    Ranks,
    CKaggleKV,
    CKaggleAbout,
    CKaggler,
    CKaggleModalRank,
    CKaggleNews,
    CKaggleInterview,
    CKaggleSupport,
    CKaggleMeetup
  },

  async asyncData ({ $content }: Context): Promise<object | void> {
    const [news] = await Promise.all([
      $content('news/detail')
        .where({ tags: { $contains: ['Kaggle'] } })
        .sortBy('publishedAt', 'desc')
        .limit(5)
        .fetch()
        .catch(handleError)
    ])

    const allMember = await $content('members')
      .where({ isKaggle: true })
      .sortBy('createdAt', 'desc')
      .fetch()
      .catch(handleError)

    const arrMember = allMember as []

    // console.log({ arrMember })

    const memberGrandmaster = arrMember.filter(
      (mem: Member) => mem.kaggleTitle === 'Kaggle Grandmaster'
    )

    const memberMaster = arrMember.filter(
      (mem: Member) => mem.kaggleTitle === 'Kaggle Master'
    )

    const memberExpert = arrMember.filter(
      (mem: Member) => mem.kaggleTitle === 'Kaggle Expert'
    )
    const memberGoogle = arrMember.filter(
      (mem: Member) => mem.kaggleTitle === 'Google Developer Expert'
    )

    const member = arrMember.filter(
      (mem: Member) => mem.kaggleTitle === ''
    )

    const members =
      [...memberGrandmaster, ...memberMaster, ...memberExpert, ...memberGoogle, ...member] || []
    return { members, news }
  },

  data () {
    const isShowModal = false as boolean
    const isShowModalKagglers = false as boolean
    const members = [] as Member[]
    const news = [] as NewsContent[]
    return { isShowModal, isShowModalKagglers, members, news }
  },

  head (): MetaInfo {
    const title = 'Kaggleで大活躍するDeNAの社内制度とは'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title)

    return {
      title,
      meta: [...extendMeta]
    }
  },

  methods: {
    toggleModal (isShow: boolean): void {
      this.isShowModal = isShow
    }
  }
})
